import React, { useEffect, useState } from 'react'
import LoginSidebar from './LoginSidebar'
import Modal from 'react-bootstrap/Modal';
import { Rating } from 'react-simple-star-rating'
import Loader from '../../utils/Loader';
import ApiConnection, { BaseUrl } from '../../utils/ApiConnection';
import moment from 'moment';
import { toast } from 'react-toastify';
import { AxiosRequest } from '../../utils/AxiosRequest';
const CompletedRides = () => {
    const [loading, setloading] = useState(false)
    const [triplist, settriplist] = useState([])
    const [cancelmodal, setcancelmodal] = useState(false);
    const [feedback, setfeedback] = useState('');
    const [rowId, setrowId] = useState('')
    const {  Getadvertisement, advertisement} = AxiosRequest()
    
    const handleClose = () => setcancelmodal(false);
    const [rating, setRating] = useState(0)
  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate)

    // other logic
  }
  // Optinal callback functions
  const onPointerEnter = () => console.log('Enter')
  const onPointerLeave = () => console.log('Leave')
  const onPointerMove = (value, index) => console.log(value, index)
  var user = JSON.parse(localStorage.getItem("user"))



  const GetTrip = async () =>{

    setloading(true)

    try {

        const response = await ApiConnection.get('trips?status=completed')
        setloading(false)
        if(response?.status === 200) {
            settriplist(response?.data?.data?.list)
        }

    } catch (e){  setloading(false)}

}

useEffect(()=>{
    GetTrip()
    Getadvertisement('rate-and-review-past-ride-page')
},[])




const RebookHandle = async (id)=>{
    setloading(true)

    try {

        const response = await ApiConnection.post(`trips/rebook/${id}`)
        setloading(false)
        if(response?.data?.success) {
            toast.success(response?.data?.message)
            GetTrip()
        } else {
            toast.error(response?.data?.message)
        }

    } catch (e){  setloading(false)}
}

const rateHandle = async (id) =>{
    setrowId(id)
    setcancelmodal(true)
}

const SubmitHandle = async () =>{
    if(feedback == ''){
        toast.error('Feedback is mandatory')
    } else {
        setloading(true)
        var FormData = require('form-data');
        var data = new FormData();
        data.append('trip_id', rowId);
        data.append('feedback', feedback);
        data.append('rate', rating);
        

        try{
            const response = await ApiConnection.post('trips/feedback', data)
            setloading(false)
            if(response?.data?.success){
                setcancelmodal(false)
                toast.success(response?.data?.message)
                GetTrip()
            }

        } catch(e){  setloading(false)}
    }
}


const ClickHandle = async (link, id) =>{

    if(link !== ''){
      window.open(link)
    }

    var FormData = require('form-data');
    var data = new FormData();
    data.append("advertisement_id", id);
    data.append("page", 'rate-and-review-past-ride-page');
    data.append("has_click", 1);
    data.append("device", 'web');
    data.append("user_id", user?.id == null ? '': user?.id);
    try {
        const  response = await ApiConnection.post(`add-management`, data)
       
        if(response?.data?.success){
           
   
       
        }  else {
          
          
          }
    } catch(err){
  
      
    
  }
}

  return (
    <div className='afterlog-sec'>
        {loading && <Loader/>}
    <div className='container'>
        <div className='row'>
        {/* <div className='col-lg-2'>
            {advertisement&&advertisement.map((item, i)=>{
                if(item?.position == 'Left'){
                
                    return (
                      item?.type == 'image' ? 
                      <div className='adv_img' onClick={()=>ClickHandle(item.link, item?.id)} >
                          <img src={BaseUrl +  item?.image} className='w-100' />
                    
                      </div>
                      :
                      <div className='adv_video text-center' >
                      <video width="190" height="150" controls onClick={()=>ClickHandle('', item?.id)}>
                          <source src={BaseUrl + item?.image} type="video/mp4" />

                        </video>
                     
                    </div>
                    )
                  }
               
              })}
          </div> */}
            <div className='col-lg-3'>
                <LoginSidebar />
            </div>
            <div className='col-lg-9'>
            <div className='afterlog-sec-right'>
            <h2 className='headingStyle text-left'>Completed Rides</h2>

{triplist.length > 0 ?
    triplist&&triplist.map((item, i)=>{
        return (
            <div className='ride-list'>
            <ul>
            <li>
                            <p><b>Pickup Date</b>: {moment(item?.pickup_date).format('DD-MM-YYYY')} </p>
                        </li>
                        <li>
                            <p><b>Pickup Time</b>: {moment(item?.pickup_date).format('LT')} </p>
                        </li>
                        <li>
                            <p><b>Pickup Location</b>: {item?.pickup_location}  </p>
                        </li>
                        <li>
                            <p><b>Drop Location: </b> {item?.drop_location}  </p>
                        </li>
                        <li>
                            <p><b>Number of Stops: </b> {item?.stops.length} </p>
                        </li>
                        <li>
                            <p><b>Estimated Fare: </b>  $ {item?.final_fare} </p>
                        </li>
                        <li>
                        <b>Driver Details: </b>
                        <div className='customer-details'>
                          {item?.
trip_driver[0]
?.image == null ?
<i className="fa-solid fa-user-tie"></i>
:
<img src={BaseUrl + item?.
trip_driver[0]?.image} />
}
                       
                        <h5>{item?.
trip_driver[0]?.name} ({item?.
    trip_driver[0]?.country_code +' ' + item?.
    trip_driver[0]?.phone})
    <br></br>
    <span>
    {Array(5).fill().map((_, i) => {
    const ratingValue = i + 1;
return ratingValue <= item?.
trip_driver[0]
?.avg_rating ? <i className="fa-solid fa-star" style={{fontSize:'15px', color:'#fdd330'}}></i> : <i className="fa-regular fa-star" style={{fontSize:'15px', color:'#fdd330'}}></i>
})}
        
      </span>
    </h5>
                          </div>
                        </li>
            </ul>
            <div className='ride-button'>
                <button className='btn btn-sm btn-primary' onClick={()=>RebookHandle(item?.id)}>Rebook</button>
                <button className='btn btn-sm btn-danger' onClick={()=>rateHandle(item?.id)}>Rate Driver</button>
            </div>
        </div>
        )
    })
:

<h5>No Record found</h5>

}

            
            </div>  
        </div>  
        {/* <div className='col-lg-2'>
            {advertisement&&advertisement.map((item, i)=>{
                if(item?.position == 'Right'){
                
                    return (
                      item?.type == 'image' ? 
                      <div className='adv_img' onClick={()=>ClickHandle(item.link, item?.id)} >
                          <img src={BaseUrl +  item?.image} className='w-100' />
                    
                      </div>
                      :
                      <div className='adv_video text-center' >
                      <video width="190" height="150" controls onClick={()=>ClickHandle('', item?.id)}>
                          <source src={BaseUrl + item?.image} type="video/mp4" />

                        </video>
                     
                    </div>
                    )
                  }
               
              })}
          </div> */}
          <div className='col-lg-12 mt-5'>
              {advertisement&&advertisement.map((item, i)=>{
                if(item?.position == 'Bottom'){
                
                    return (
                      item?.type == 'image' ? 
                      <div className='adv_img' onClick={()=>ClickHandle(item.link, item?.id)} >
                          <img src={BaseUrl +  item?.image} className='w-100' />
                    
                      </div>
                      :
                      <div className='adv_video text-center' >
                      <video width="500" height="400" controls onClick={()=>ClickHandle('', item?.id)}>
                          <source src={BaseUrl + item?.image} type="video/mp4" />

                        </video>
                     
                    </div>
                    )
                  }
               
              })}
          </div>
        </div>  
    </div>
    <Modal
        show={cancelmodal}
        centered
        onHide={handleClose}
      >
 <Modal.Header closeButton>
          <Modal.Title>Rate Your Trip
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-left py-2'>
            <div className='form-group'>
                <Rating
                onClick={handleRating}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
                onPointerMove={onPointerMove}
                /* Available Props */
            />
            </div>
            <div className='form-group'>
                <label>Your Feedback
                </label>
                <textarea className='form-control' placeholder='Your Feedback' value={feedback} onChange={(e)=>setfeedback(e.target.value)}></textarea>
            </div>
            <div className='form-group text-right'>
                <button className='btn btn-primary' onClick={SubmitHandle}>Submit</button>
            </div>
        </Modal.Body>
      
      </Modal>
</div>
  )
}

export default CompletedRides