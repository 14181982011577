import React, { useRef, useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import { sidebarAction } from '../../redux/SidebarSlice';
import "react-datepicker/dist/react-datepicker.css";
import { IMAGE } from '../../common/Theme';
import { StandaloneSearchBox, LoadScript, Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import Loader from '../../utils/Loader';
import ApiConnection, { BaseUrl } from '../../utils/ApiConnection';
import { toast } from 'react-toastify';
import moment from 'moment';
import { AxiosRequest } from '../../utils/AxiosRequest';
import { useParams } from 'react-router-dom';

const SheduleRide = () => {

    const sidebarShow = useSelector((state) => state.SidebarSlice.isSidebarshow)
    const dispatch = useDispatch()
    const {  Getadvertisement, advertisement} = AxiosRequest()
    const user = JSON.parse(localStorage.getItem("user"));




    const [loading, setloading] = useState(false)
    
    const [pickupDate, setpickupDate] = useState(new Date());
    const [pickupTime, setpickupTime] = useState(new Date());
    const [carttype, setcarttype] = useState("")
    const [pickuplocation, setpickuplocation] = useState('')
    const [droplocation, setdroplocation] = useState('')
    const [pickuplat, setpickuplat] = useState('')
    const [pickuplng, setpickuplng] = useState('')
    const [droplat, setdroplat] = useState('')
    const [droplng, setdroplng] = useState('')
    const [stoploclist, setstoploclist] = useState([
        {
            address:'',
        }
    ])
    const [isdroplist, setisdroplist] = useState(false)
    const [tripdata, settripdata] = useState("")
    const inputRef = useRef()
    const inputRef1 = useRef()
    const inputRef2 = useRef()

    const {id} = useParams()

    useEffect(()=>{
        GetData()
      },[])

    useEffect(()=>{
      dispatch(sidebarAction(!sidebarShow)) 
      Getadvertisement('booking-activity-page')

    },[])

  

 


   

    const GetData = async() =>{

        setloading(true)

        try {

            const response = await ApiConnection.get(`trips/${id}`)
            setloading(false)
            if(response?.status === 200) {
                if(response?.data?.data?.trip?.stops.length > 0){
                    setisdroplist(true)
                    var TempArr = []
                    response?.data?.data?.trip?.stops.map((item)=>{
                        TempArr.push({
                            address:item?.stop_location
                        })
                    })
                  
            
                    setstoploclist(TempArr)
                }
               
                setpickupDate(new Date(moment(response?.data?.data?.trip?.pickup_date).format('MM-DD-YYYY')))
                setcarttype(response?.data?.data?.trip?.cartype)
                setpickuplocation(response?.data?.data?.trip?.pickup_location)
                pickuplat(response?.data?.data?.trip?.pickup_lat)
                pickuplng(response?.data?.data?.trip?.pickup_long)
                setdroplocation(response?.data?.data?.trip?.drop_location)
                setdroplat(response?.data?.data?.trip?.drop_lat)
                setdroplat(response?.data?.data?.trip?.drop_long)
                //setpickupTime(new Date(response?.data?.data?.trip?.pickup_time))
             
           
              
               
               

            }

        } catch (e){  if(e.response?.status == 500){
            setloading(false)
            console.log(e)
            toast.error(e.response?.data?.message)
        }}

    }



      const handleplacesChanged = () =>{

        const [place] = inputRef.current.getPlaces();

        if(place){
           
           var _address = place.formatted_address;
        // var _country = "";
        // var _city = "";
        // var _state = "";
        // var _zipcode = ""
        var _lat = place.geometry.location.lat()
        var _lng = place.geometry.location.lng()
        setpickuplat(_lat)
        setpickuplng(_lng)
        setpickuplocation(_address)
        // for (const component of place.address_components){
        //   const addressType = component.types[0]
        //   console.log(addressType)
        //   if(addressType == "administrative_area_level_3"){
        //     _city = component.long_name
        //   } 
        //   if(addressType == "administrative_area_level_1"){
        //     _state = component.long_name
        //   }
        //   if(addressType == "country"){
        //     _country = component.long_name
        //   }
        //   if(addressType == "postal_code"){
        //     _zipcode = component.long_name
        //   }
        // }
        }

      }

      const handleplacesChangedone = () =>{

        const [place] = inputRef1.current.getPlaces();

        if(place){
            console.log(place)
           var _address = place.formatted_address;
           var _lat = place.geometry.location.lat()
           var _lng = place.geometry.location.lng()
           setdroplat(_lat)
           setdroplng(_lng)
           setdroplocation(_address)

        }

      }

      const AddHandle = () =>{

        let obj = {
            address:'',
        }

        setstoploclist([...stoploclist, obj])

      }

      const RemoveHandle = (indx) =>{

        const rows = [...stoploclist];
        rows.splice(indx, 1);
        setstoploclist(rows);
      }

      const handledropChanged = (index) =>{

        const [place] = inputRef2.current.getPlaces();

        if(place){
          
           var _address = place.formatted_address;
           let obj = {
            address:_address, 
          }
          
           const rowsInput = [...stoploclist];
          rowsInput[index] = obj;

          setstoploclist(rowsInput);

        }

      }



      const SubmitHandle = async () =>{

        setloading(true)

             var FormData = require('form-data');
            var data = new FormData();
            data.append('customer_type', 'old');
            data.append('customer', Number(user.id));
            data.append("date", moment(pickupDate).format('YYYY-MM-DD'));
            data.append("time", moment(pickupTime).format('HH:mm:ss'));
            data.append("pickup_location", pickuplocation);
            data.append("pickup_lat", pickuplat);
            data.append("pickup_long", pickuplng);
            data.append("destination", droplocation);
            data.append("drop_lat", droplat);
            data.append("drop_long", droplng);
            data.append("car_type", carttype);
            data.append("_method", 'PUT');
            
            {stoploclist.map((item)=>{
                data.append("stop[]", item?.address);
            })}

        try{

            const response = await ApiConnection.post(`trips`, data)
            setloading(false)
            if(response?.status === 200) {
              toast.success(response?.data?.message)
            }


        } catch(e){  setloading(false)

            toast.error(e.response?.data?.message)
        }


      }

      const StatusHandle = async () =>{
      
            var FormData = require('form-data');
            var data = new FormData();
            data.append('id', id);
            data.append('action', 'void');
            

            try{
                const response = await ApiConnection.post('trips/change-status', data)
                setloading(false)
                if(response?.data?.success){
                    SubmitHandle()
                }

            } catch(e){  setloading(false)}
        
    }

       
      const ClickHandle = async (link, id) =>{

        if(link !== ''){
          window.open(link)
        }
    
        var FormData = require('form-data');
        var data = new FormData();
        data.append("advertisement_id", id);
        data.append("page", 'booking-activity-page');
        data.append("has_click", 1);
        data.append("device", 'web');
        data.append("user_id", user?.id == null ? '': user?.id);
        try {
            const  response = await ApiConnection.post(`add-management`, data)
           
            if(response?.data?.success){
               
       
           
            }  else {
              
              
              }
        } catch(err){
      
          
        
      }
    }




  return (
    <LoadScript googleMapsApiKey="AIzaSyBrRtkwvBcSh3_uISG8CVAX2IqykHdQEP4"
    libraries={["places"]}>
    <div className='about-sec'>
    {loading && <Loader/>}
    <div className='container-fluid'>
            <div className='row'>
            <div className='col-lg-2'>
            {advertisement&&advertisement.map((item, i)=>{
                if(item?.position == 'Left'){
                
                    return (
                      item?.type == 'image' ? 
                      <div className='adv_img' onClick={()=>ClickHandle(item.link, item?.id)} >
                          <img src={BaseUrl +  item?.image} className='w-100' />
                    
                      </div>
                      :
                      <div className='adv_video text-center' >
                      <video width="190" height="150" controls onClick={()=>ClickHandle('', item?.id)}>
                          <source src={BaseUrl + item?.image} type="video/mp4" />

                        </video>
                     
                    </div>
                    )
                  }
               
              })}
          </div>
                <div className='col-lg-8'>
                <h2 className='headingStyle'>Change Shedule</h2>
        <div className='row'>
            <div className='col-lg-6'>
                <div className='ride-form-field'>
                    <label>PickUp Date:</label>
                    <DatePicker className='form-control' selected={pickupDate} onChange={(date) => setpickupDate(date)} />
                    <span><img src={IMAGE.calender_icon} alt="calender" /></span>
                </div>
            </div>
            <div className='col-lg-6'>
                <div className='ride-form-field'>
                    <label>PickUp Time:</label>
                    <DatePicker className='form-control'
                     selected={pickupTime} onChange={(date) => setpickupTime(date)}
                     showTimeSelect
                     showTimeSelectOnly
                     timeIntervals={15}
                     timeCaption="Time"
                     dateFormat="h:mm aa"
                     />
                    <span><img src={IMAGE.clock_icon} alt="calender" /></span>
                </div>
            </div>
            <div className='col-lg-9'>
                <div className='ride-form-field address-field'>
                    <label>Select Pickup Location:</label>
                        <StandaloneSearchBox
                        onLoad={ref =>(inputRef.current = ref)}
                        onPlacesChanged={handleplacesChanged}
                        className="address-auto"
                        >
                             <input
                            type="text"
                        className="form-control"
                        placeholder={pickuplocation}
                        />
                        </StandaloneSearchBox>
           

                    <span><img src={IMAGE.loc_icon} alt="calender" /></span>
                </div>
            </div>
            {/* <div className='col-lg-3'>
                <div className='ride-form-field'>
                    <h6>Choose From Map</h6>
                </div>
            </div> */}
            <div className='col-lg-12'>
                <div className='ride-form-field'>
                    <label>Select Car Type:</label>
                    <ul className='cart_type_list'>
                        <li>
                            <button className={carttype == 'Sedan' ? 'car-type active' : 'car-type'}  onClick={()=>setcarttype('Sedan')}>
                                <img src={IMAGE.seden} alt="calender" />
                                <br></br>
                                Sedan
                            </button>
                        </li>
                        <li>
                            <button className={carttype == "Deluxe" ? 'car-type active' : 'car-type'}  onClick={()=>setcarttype('Deluxe')}>
                                <img src={IMAGE.delux} alt="calender" />
                                <br></br>
                                Deluxe
                            </button>
                        </li>
                        <li>
                            <button className={carttype == 'Custom' ? 'car-type active' : 'car-type'}  onClick={()=>setcarttype('Custom')}>
                                <img src={IMAGE.custom} alt="calender" />
                                <br></br>
                                Custom
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='col-lg-10'>
                <div className='ride-form-field address-field'>
                    <label>Select Destination:</label>
                    <StandaloneSearchBox
                        onLoad={ref =>(inputRef1.current = ref)}
                        onPlacesChanged={handleplacesChangedone}
                        className="address-auto"
                        >
                             <input
                            type="text"
                            placeholder={droplocation}
                        className="form-control"
                        />
                        </StandaloneSearchBox>
                    <span><img src={IMAGE.loc_icon} alt="calender" /></span>
                </div>
            </div>
            {/* <div className='col-lg-2'>
                <div className='ride-form-field'>
                    <h6>Choose From Map</h6>
                </div>
            </div> */}
    
        </div>
        <div className='ride-form-field'>
                    <h3>Add Stop(s) &nbsp;  &nbsp; 
                        {isdroplist ? 
                        <button className='plusbtn' onClick={()=>setisdroplist(false)}><img src={IMAGE.cross_icon} /></button>
                       
                         :
                         <button className='plusbtn' onClick={()=>setisdroplist(true)}><img src={IMAGE.plus_icon} /></button>
                    }
                        
                    </h3>
          </div> 

{isdroplist && 
<div className='drop-listed'>

        <div className='stop-list'>
            <div className='stop-list-lft'>
               <img src={IMAGE.pick_icon} alt='' />
            </div>
            <div className='stop-list-mid'>
               <input type='text' className='form-control' placeholder='Pick up' value={pickuplocation} />
            </div>
            <div className='stop-list-rht'>
               
            </div>
        </div>

        {stoploclist.map((item, index)=>{


            return (
                <div className='stop-list'>
            <div className='stop-list-lft'>
             <span>{index + 1}</span>
            </div>
            <div className='stop-list-mid'>
            <StandaloneSearchBox
                        onLoad={ref =>(inputRef2.current = ref)}
                        onPlacesChanged={()=>handledropChanged(index)}
                        className="address-auto"
                        >
                             <input
                            type="text"
                            placeholder={item?.address}
                        className="form-control"
                        />
                        </StandaloneSearchBox>
                 
            </div>
            <div className='stop-list-rht'>
                {index === 0 ?
               <button onClick={AddHandle}>
                    <img src={IMAGE.plus_icon} alt="calender" />
               </button>
               :
           
               <button onClick={()=>RemoveHandle(index)}>
                    <img src={IMAGE.cross_icon} alt="calender" />
               </button>
        }
            </div>
        </div>
            )
        })}


        <div className='stop-list'>
            <div className='stop-list-lft'>
            <img src={IMAGE.location_fill} alt='' />
            </div>
            <div className='stop-list-mid'>
               <input type='text' className='form-control' placeholder='Drop' value={droplocation} />
            </div>
            <div className='stop-list-rht'>
          
            </div>
        </div>
        </div>
}
        <div className='text-center mt-5'>
            <button className='submitBtn' onClick={StatusHandle}>Book Now</button>
        </div>
                </div>
                <div className='col-lg-2'>
            {advertisement&&advertisement.map((item, i)=>{
                if(item?.position == 'Right'){
                
                    return (
                      item?.type == 'image' ? 
                      <div className='adv_img' onClick={()=>ClickHandle(item.link, item?.id)} >
                          <img src={BaseUrl +  item?.image} className='w-100' />
                    
                      </div>
                      :
                      <div className='adv_video text-center' >
                      <video width="190" height="150" controls onClick={()=>ClickHandle('', item?.id)}>
                          <source src={BaseUrl + item?.image} type="video/mp4" />

                        </video>
                     
                    </div>
                    )
                  }
               
              })}
          </div>
          <div className='col-lg-12 mt-5'>
              {advertisement&&advertisement.map((item, i)=>{
                if(item?.position == 'Bottom'){
                
                    return (
                      item?.type == 'image' ? 
                      <div className='adv_img' onClick={()=>ClickHandle(item.link, item?.id)} >
                          <img src={BaseUrl +  item?.image} className='w-100' />
                    
                      </div>
                      :
                      <div className='adv_video text-center' >
                      <video width="500" height="400" controls onClick={()=>ClickHandle('', item?.id)}>
                          <source src={BaseUrl + item?.image} type="video/mp4" />

                        </video>
                     
                    </div>
                    )
                  }
               
              })}
          </div>
            </div>


      
    </div>
</div>
</LoadScript>
  )
}

export default SheduleRide