import { useEffect, useState } from 'react';

const useLoadScript = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  var apiKey = 'AIzaSyBrRtkwvBcSh3_uISG8CVAX2IqykHdQEP4'

  useEffect(() => {
    if (!apiKey) return;

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBrRtkwvBcSh3_uISG8CVAX2IqykHdQEP4&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = () => setIsScriptLoaded(true);

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [apiKey]);

  return isScriptLoaded;
};

export default useLoadScript