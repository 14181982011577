import React, { useEffect, useState } from 'react'
import LoginSidebar from './LoginSidebar'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ApiConnection, { BaseUrl } from '../../utils/ApiConnection'
import Loader from '../../utils/Loader'
import { AxiosRequest } from '../../utils/AxiosRequest'

const ChangePassword = () => {
    const [loading, setloading] = useState(false)
    const [oldpassword, setoldpassword] = useState("")
    const [newpassword, setnewpassword] = useState("")
    const [confirmpassword, setconfirmpassword] = useState("")
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState( <i class="fa-solid fa-eye-slash"></i>);
    const [ntype, setnType] = useState('password');
    const [nicon, setnIcon] = useState( <i class="fa-solid fa-eye-slash"></i>);
    const [ctype, setcType] = useState('password');
    const [cicon, setcIcon] = useState( <i class="fa-solid fa-eye-slash"></i>);
    const {  Getadvertisement, advertisement} = AxiosRequest()
    let navigate = useNavigate()
    var user = JSON.parse(localStorage.getItem("user"))
    const SubmitHandler = async () =>{
        if(oldpassword == ''){
            toast.error("Existing password is required");
          } else if(newpassword == ''){
            toast.error("New Password is required");
          } else if(newpassword !== confirmpassword){
            toast.error("Confirm Password does not match");
          } else {
            setloading(true)
            try{
                var FormData = require('form-data');
                var data = new FormData();
                data.append('existing_password', oldpassword);
                data.append('password', newpassword);
                data.append('c_password', confirmpassword);
                const response = await ApiConnection.post(`change-password`, data);
                if(response.status === 200){
                    setloading(false)
                    toast.success(response?.data?.message);
                    navigate("/dashboard")
                } else {
                    setloading(false)
                }

            } catch(err){
                setloading(false)
                if(err.response.status === 500){
                      toast.error(err.response?.data?.message);   
                  
                }
        }
          }
    }

    useEffect(()=>{
        Getadvertisement('change-password-page')
      },[])

      const ClickHandle = async (link, id) =>{

        if(link !== ''){
          window.open(link)
        }
    
        var FormData = require('form-data');
        var data = new FormData();
        data.append("advertisement_id", id);
        data.append("page", 'change-password-page');
        data.append("has_click", 1);
        data.append("device", 'web');
        data.append("user_id", user?.id == null ? '': user?.id);
        try {
            const  response = await ApiConnection.post(`add-management`, data)
           
            if(response?.data?.success){
               
       
           
            }  else {
              
              
              }
        } catch(err){
      
          
        
      }
    }

    const handleToggle = () => {
        if (type==='password'){
           setIcon(<i class="fa-solid fa-eye"></i>);
           setType('text')
        } else {
           setIcon(<i class="fa-solid fa-eye-slash"></i>)
           setType('password')
        }
     }
     const handleToggle1 = () => {
        if (ntype==='password'){
           setnIcon(<i class="fa-solid fa-eye"></i>);
           setnType('text')
        } else {
           setnIcon(<i class="fa-solid fa-eye-slash"></i>)
           setnType('password')
        }
     }
     const handleToggle2 = () => {
        if (ctype==='password'){
           setcIcon(<i class="fa-solid fa-eye"></i>);
           setcType('text')
        } else {
           setcIcon(<i class="fa-solid fa-eye-slash"></i>)
           setcType('password')
        }
     }


    return (
        <div className='afterlog-sec'>
             {loading && <Loader/>}
        <div className='container-fluid'>
            <div className='row'>
            <div className='col-lg-2'>
            {advertisement&&advertisement.map((item, i)=>{
                if(item?.position == 'Left'){
                
                    return (
                      item?.type == 'image' ? 
                      <div className='adv_img' onClick={()=>ClickHandle(item.link, item?.id)} >
                          <img src={BaseUrl +  item?.image} className='w-100' />
                    
                      </div>
                      :
                      <div className='adv_video text-center' >
                      <video width="190" height="150" controls onClick={()=>ClickHandle('', item?.id)}>
                          <source src={BaseUrl + item?.image} type="video/mp4" />

                        </video>
                     
                    </div>
                    )
                  }
               
              })}
          </div>
                <div className='col-lg-3'>
                    <LoginSidebar />
                </div>
                <div className='col-lg-5'>
                <div className='afterlog-sec-right'>
                <h2 className='headingStyle text-left'>Change Password</h2>
            <div className='row'>
            <div className='col-lg-6'>
                <div className='form-group'>
                    <label>Existing Password</label>
                    <input type={type} className="form-control" placeholder="XXXXXXX"
                      value={oldpassword}  
                      onChange={(e)=>setoldpassword(e.target.value)}
                    />
                       <span class="flex justify-around items-center" style={{    
                    position: 'absolute',
top: '43px',
right: '18px'}} onClick={handleToggle}>
               {icon}
                  
                </span>
                </div>
            </div>
            <div className='col-lg-6'>
                <div className='form-group'>
                    <label>New Password</label>
                    <input type={ntype} className="form-control" placeholder="XXXXXXX"
                    value={newpassword}  
                    onChange={(e)=>setnewpassword(e.target.value)}
                    />
                       <span class="flex justify-around items-center" style={{    
                    position: 'absolute',
top: '43px',
right: '18px'}} onClick={handleToggle1}>
               {nicon}
                  
                </span>
                </div>
            </div>
            <div className='col-lg-6'>
                <div className='form-group'>
                    <label>Confirm New Password</label>
                    <input type={ctype} className="form-control" placeholder="XXXXXXX"
                     value={confirmpassword}  
                     onChange={(e)=>setconfirmpassword(e.target.value)}
                    />
                       <span class="flex justify-around items-center" style={{    
                    position: 'absolute',
top: '43px',
right: '18px'}} onClick={handleToggle2}>
               {cicon}
                  
                </span>
                </div>
            </div>
          
            <div className='col-lg-12 mt-2'>
     <div className='form-group text-right'>
                <button type='button' className='submitBtn' onClick={SubmitHandler}>Update</button>
            </div>
            </div>
            </div>
                </div>
            </div>
            <div className='col-lg-2'>
            {advertisement&&advertisement.map((item, i)=>{
                if(item?.position == 'Right'){
                
                    return (
                      item?.type == 'image' ? 
                      <div className='adv_img' onClick={()=>ClickHandle(item.link, item?.id)} >
                          <img src={BaseUrl +  item?.image} className='w-100' />
                    
                      </div>
                      :
                      <div className='adv_video text-center' >
                      <video width="190" height="150" controls onClick={()=>ClickHandle('', item?.id)}>
                          <source src={BaseUrl + item?.image} type="video/mp4" />

                        </video>
                     
                    </div>
                    )
                  }
               
              })}
          </div>
          <div className='col-lg-12 mt-5'>
              {advertisement&&advertisement.map((item, i)=>{
                if(item?.position == 'Bottom'){
                
                    return (
                      item?.type == 'image' ? 
                      <div className='adv_img' onClick={()=>ClickHandle(item.link, item?.id)} >
                          <img src={BaseUrl +  item?.image} className='w-100' />
                    
                      </div>
                      :
                      <div className='adv_video text-center' >
                      <video width="500" height="400" controls onClick={()=>ClickHandle('', item?.id)}>
                          <source src={BaseUrl + item?.image} type="video/mp4" />

                        </video>
                     
                    </div>
                    )
                  }
               
              })}
          </div>
            </div>
        </div>
    </div>
      )
}

export default ChangePassword